import PropTypes from 'prop-types';

/*Basket propTypes*/
export const basketAccessoriesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    // data fetched from the API
    itemData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      salesforceId: PropTypes.string,
      included: PropTypes.bool,
      standard: PropTypes.bool,
      automatedOffer: PropTypes.bool,
    }),
    id: PropTypes.string.isRequired,
    count: PropTypes.number,
    addedByUser: PropTypes.bool,
  })
);

export const basketQuestionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    required: PropTypes.bool.isRequired,
    question: PropTypes.string.isRequired,
    answerType: PropTypes.oneOf([
      'text_input',
      'number',
      'number_range',
      'selection',
    ]).isRequired,
    choices: PropTypes.arrayOf(PropTypes.string),
    hasUnit: PropTypes.bool.isRequired,
    unit: PropTypes.string,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    ]).isRequired,
  })
);

export const basketItemPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rentalObject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    productReferenceId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    salesforceId: PropTypes.string,
    productGroupName: PropTypes.string,
    productGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    automatedOffer: PropTypes.bool,
    invoiceBasis: PropTypes.string,
  }),
  count: PropTypes.number,
  rentalHours: PropTypes.number,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  accessories: basketAccessoriesPropTypes,
  description: PropTypes.string,
  questions: basketQuestionsPropTypes,
});

export const urlObjectPropTypes = PropTypes.shape({
  auth: PropTypes.string,
  hash: PropTypes.string,
  host: PropTypes.string,
  hostname: PropTypes.string,
  href: PropTypes.string,
  pathname: PropTypes.string,
  protocol: PropTypes.string,
  search: PropTypes.string,
  slashes: PropTypes.bool,
  port: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

/*Blog propTypes*/
export const blogPostPropType = PropTypes.shape({
  id: PropTypes.number,
  attributes: PropTypes.shape({
    blogCategory: PropTypes.object,
    createdAt: PropTypes.string,
    excerpt: PropTypes.string,
    heroImage: PropTypes.object,
    locale: PropTypes.string,
    localizations: PropTypes.object,
    metadata: PropTypes.object,
    publishedAt: PropTypes.string,
    seo: PropTypes.object,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
});

export const blogCategoryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  attributes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      id: PropTypes.number.isRequired,
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }),
});

export const heroVariantPropType = PropTypes.oneOf([
  'category_page',
  'default',
]);

export const craneWizardPropType = PropTypes.shape({
  activeStep: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).isRequired,
  type: PropTypes.string,
  subType: PropTypes.string,
  hookHeight: PropTypes.string,
  distance: PropTypes.string,
  maxWeight: PropTypes.string,
  disturbanceDistance: PropTypes.string,
  disturbanceHeight: PropTypes.string,
  count: PropTypes.number,
  gender: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  countryCode: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  crefo: PropTypes.shape({
    crefoNumber: PropTypes.string,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    location: PropTypes.string,
    streetAndHouseNumber: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
  }),
  addNonCrefoCompany: PropTypes.bool,
  nonCrefoCompanyName: PropTypes.string,
  nonCrefoCompanyAddress: PropTypes.shape({
    street: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  email: PropTypes.string,
  deliveryAddress: PropTypes.shape({
    street: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  marketingConsent: PropTypes.bool,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
});

export const opportunityLineItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  opportunityId: PropTypes.string,
  productId: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.number,
  rentalStartDate: PropTypes.string,
  rentalEndDate: PropTypes.string,
  itemDescription: PropTypes.string,
  otherText: PropTypes.string,
  unitPrice: PropTypes.number,
  linkedTo: PropTypes.string,
  machineOliId: PropTypes.string,
  machineOliName: PropTypes.string,
  transportCosts: PropTypes.string,
  description: PropTypes.string,
  invoiceBasis: PropTypes.string,
  furtherCostsDescription1: PropTypes.string,
  furtherCostsPrice1: PropTypes.string,
  furtherCostsDescription2: PropTypes.string,
  furtherCostsPrice2: PropTypes.string,
  furtherCostsDescription3: PropTypes.string,
  furtherCostsPrice3: PropTypes.string,
  ownerComment: PropTypes.string,
  status: PropTypes.string,
  transportCostToSite: PropTypes.any,
  transportCostFromSite: PropTypes.any,
  technicianContactFullNameWithSalutation: PropTypes.string,
  technicianContactPhone: PropTypes.string,
});

export const breadcrumbPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  metadata: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
});

breadcrumbPropTypes.parent = breadcrumbPropTypes;

export const breadcrumbsPropType = PropTypes.arrayOf(breadcrumbPropTypes);
