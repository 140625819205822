import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import getConfig from '@/configs/global';
import { pageView } from '@/helpers/dataLayer';
import { generateHreflangLinks } from '@/helpers/hreflang';
import extractSeoData from '@/helpers/seo';
import strapiApi from '@/helpers/strapi/api';
import {
  generateBreadcrumbStructuredData,
  generateFaqStructuredData,
  generateLogoStructuredData,
} from '@/helpers/strapi/structuredData';
import { useSourceContext } from '@/hooks/useSourceContext';
import DefaultLayout from '@/layouts/default';

const DEFAULT_PAGE_TEMPLATE = dynamic(() => import('@/templates/Page/Page'));

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

/**
 * It's heavily encouraged to benefit from optional chaining in this file,
 * given that data from Strapi might be provided corrupted or deficient.
 * In such scenarios, optional chaining allows us to have non-broken components.
 */
const DynamicPage = ({ page, breadcrumbs, refererPathname }) => {
  const { asPath } = useRouter();
  const Page = DEFAULT_PAGE_TEMPLATE;
  const seoData = page?.attributes?.seo;
  const {
    keywords,
    ogImageUrl,
    twitterTitle,
    twitterDescription,
    twitterImageUrl,
  } = extractSeoData(seoData);
  const [pathname] = asPath.split('?');
  const { setLeadReference } = useSourceContext();
  const ogUrl = `${BASE_URL}${pathname}`;

  useEffect(() => {
    if (page?.attributes?.title) {
      pageView({ title: page.attributes.title });
    }
    setLeadReference(pathname);
  }, [pathname, page?.attributes?.title, setLeadReference]);

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <Head>
        <title>{seoData?.metaTitle || page.attributes.title}</title>
        <meta
          key="description"
          name="description"
          content={seoData?.metaDescription || page.attributes.description}
        />
        {keywords && <meta name="keywords" content={keywords}></meta>}
        <meta
          property="og:title"
          content={seoData?.metaTitle || page.attributes.title}
          key="og:title"
        />
        <meta
          property="og:description"
          content={seoData?.metaDescription || page.attributes.description}
          key="og:description"
        />
        {ogImageUrl && (
          <>
            <meta property="og:image" content={ogImageUrl} key="og:image" />
            <meta
              property="og:image:secure_url"
              content={ogImageUrl}
              key="og:image:secure_url"
            />
          </>
        )}
        <meta property="og:url" content={ogUrl}></meta>
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:locale" content="de_DE" key="og:locale" />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        {twitterTitle && (
          <meta
            name="twitter:title"
            content={twitterTitle}
            key="twitter:title"
          />
        )}
        {twitterDescription && (
          <meta
            name="twitter:description"
            content={twitterDescription}
            key="twitter:description"
          />
        )}
        {twitterImageUrl && (
          <meta
            name="twitter:image"
            content={twitterImageUrl}
            key="twitter:image"
          />
        )}
        {seoData?.structuredData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(seoData?.structuredData),
            }}
            key="page-jsonld"
          />
        )}
        {generateBreadcrumbStructuredData(breadcrumbs)}
        {generateFaqStructuredData(page)}
        {generateLogoStructuredData(pathname)}
        {pathname === '/' && generateHreflangLinks()}
      </Head>
      <Page page={page} refererPathname={refererPathname} />
    </DefaultLayout>
  );
};

DynamicPage.propTypes = {
  page: PropTypes.object,
};

DynamicPage.defaultProps = {
  page: undefined,
};

export async function getServerSideProps({ res, req, resolvedUrl }) {
  const [pathname] = resolvedUrl.split('?');

  const pageData = (await strapiApi.getPageByPathname(pathname)).data.data;
  const statusCode = pageData?.id ? 200 : 404;
  const referer = req.headers?.referer;
  let refererPathname;

  let breadcrumbsData = null;
  if (statusCode === 200) {
    breadcrumbsData = (await strapiApi.getBreadcrumbsByPathname(pathname)).data
      .data;
  }

  /**
   * `res` is available only on the server-side where `statusCode` is meaningful
   */
  if (res) {
    res.statusCode = statusCode;
  }

  if (referer) {
    try {
      refererPathname = new URL(referer).pathname;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  return {
    props: {
      page: pageData,
      breadcrumbs: breadcrumbsData,
      refererPathname: refererPathname || null,
    },
    notFound: statusCode === 404,
  };
}

export default DynamicPage;
